import './Nav.scss'

import React from 'react';

const Nav = () => {
    return (
        <div className='nav' id='nav'>
            <ul className='nav__list'>
                <li className='nav__list--item'>
                    <a href='#about'>About</a>
                </li>
                <li className='nav__list--item'>
                    <a href='#projects'>Projects</a>
                </li>
                <li className='nav__list--item'>
                    <a href='#contact'>Contact</a>
                </li>
            </ul>
        </div>
    );
};

export default Nav;