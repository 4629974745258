import './About.scss'
import Rob from '../../assets/images/Rob.jpg'
import ReactLogo from '../../assets/logos/react_logo.png'
import ReactRouterLogo from '../../assets/logos/react-router_logo.png'
import SassLogo from '../../assets/logos/sass_logo.png'
import NodeLogo from '../../assets/logos/node_logo.png'
import MySQLLogo from '../../assets/logos/MySQL_logo.png'
import KnexLogo from '../../assets/logos/knex_js_logo.png'
import JWTLogo from '../../assets/logos/jwt_logo.png'
import ExpressLogo from '../../assets/logos/expressJS_logo.png'
import BCryptLogo from '../../assets/logos/bcrypt_logo.jpg'
import BatesLogo from '../../assets/logos/bates_logo_new.png'
import AHSLogo from '../../assets/logos/ahs_logo.png'
import React, { Component } from 'react';

class About extends Component {

    state = {
        renderAbout: false,
        colorChange1: false,
        colorChange2: false,
        colorChange3: false,
        colorChange4: false,
        colorChange5: false,
        colorChange6: false,
        colorChange7: false,
        colorChange8: false,
        colorChange9: false,
        colorChange10: false,
        colorChange11: false,
    }

    handleRenderAbout = () => {
        if (this.state.renderAbout === false) {
            this.setState({renderAbout: true})
        }
        else {
            this.setState({renderAbout: false})
        }
    }

    randomizeColors = () => {
        let rgbArray = [0, 0, 0]
        for(let i=0;i<rgbArray.length;i++){
            rgbArray[i] = Math.floor(Math.random() * 255) + 1
        }
        let colorDecider = Math.floor(Math.random() * 3) + 1
        let colorDeciderTwo = Math.floor(Math.random() * 2) + 1
        if (colorDecider === 1) {
            rgbArray[0] = 0
            if (colorDeciderTwo === 1) {
                rgbArray[1] = 255
            }
            else {rgbArray[2] = 255}
        }
        else if (colorDecider === 2) {
            rgbArray[1] = 0
            if (colorDeciderTwo === 1) {
                rgbArray[0] = 255
            }
            else {rgbArray[2] = 255}
        }
        else if (colorDecider === 3) {
            rgbArray[2] = 0
            if (colorDeciderTwo === 1) {
                rgbArray[0] = 255
            }
            else {rgbArray[1] = 255}
        }
        const spanStyle = {
            animation: 'waviy .2s 1',
            // animationDelay: `calc(.1s * ${Math.floor(Math.random() * 10) + 1})`,
            color: `rgb(${rgbArray[0]}, ${rgbArray[1]},
            ${rgbArray[2]})`,
            cursor: 'pointer'
        }
        return spanStyle;
    }

    handleColorChange1 = () => {
        if (this.state.colorChange1 === false) {
            this.setState({colorChange1: true})
        }
        else {this.setState({colorChange1: false})}
    }
    handleColorChange2 = () => {
        if (this.state.colorChange2 === false) {
            this.setState({colorChange2: true})
        }
        else {this.setState({colorChange2: false})}
    }
    handleColorChange3 = () => {
        if (this.state.colorChange3 === false) {
            this.setState({colorChange3: true})
        }
        else {this.setState({colorChange3: false})}
    }
    handleColorChange4 = () => {
        if (this.state.colorChange4 === false) {
            this.setState({colorChange4: true})
        }
        else {this.setState({colorChange4: false})}
    }
    handleColorChange5 = () => {
        if (this.state.colorChange5 === false) {
            this.setState({colorChange5: true})
        }
        else {this.setState({colorChange5: false})}
    }
    handleColorChange6 = () => {
        if (this.state.colorChange6 === false) {
            this.setState({colorChange6: true})
        }
        else {this.setState({colorChange6: false})}
    }
    handleColorChange7 = () => {
        if (this.state.colorChange7 === false) {
            this.setState({colorChange7: true})
        }
        else {this.setState({colorChange7: false})}
    }
    handleColorChange8 = () => {
        if (this.state.colorChange8 === false) {
            this.setState({colorChange8: true})
        }
        else {this.setState({colorChange8: false})}
    }
    handleColorChange9 = () => {
        if (this.state.colorChange9 === false) {
            this.setState({colorChange9: true})
        }
        else {this.setState({colorChange9: false})}
    }
    handleColorChange10 = () => {
        if (this.state.colorChange10 === false) {
            this.setState({colorChange10: true})
        }
        else {this.setState({colorChange10: false})}
    }
    handleColorChange11 = () => {
        if (this.state.colorChange11 === false) {
            this.setState({colorChange11: true})
        }
        else {this.setState({colorChange11: false})}
    }



    render() {
        const initialStyle = {
            color: `rgb(${255}, ${255}, ${255})`,
            cursor: 'pointer'
        }
        const spanStyle1 = this.state.colorChange1? this.randomizeColors() : initialStyle
        const spanStyle2 = this.state.colorChange2? this.randomizeColors() : initialStyle
        const spanStyle3 = this.state.colorChange3? this.randomizeColors() : initialStyle
        const spanStyle4 = this.state.colorChange4? this.randomizeColors() : initialStyle
        const spanStyle5 = this.state.colorChange5? this.randomizeColors() : initialStyle
        const spanStyle6 = this.state.colorChange6? this.randomizeColors() : initialStyle
        const spanStyle7 = this.state.colorChange7? this.randomizeColors() : initialStyle
        const spanStyle8 = this.state.colorChange8? this.randomizeColors() : initialStyle
        const spanStyle9 = this.state.colorChange9? this.randomizeColors() : initialStyle
        const spanStyle10 = this.state.colorChange10? this.randomizeColors() : initialStyle
        const spanStyle11 = this.state.colorChange11? this.randomizeColors() : initialStyle

        return (
            <div className='about' id='about'>
                <div className='about__top'>
                    <div className='about__top--info'>
                        <div className='about__top--info--description'>
                            <img src={ReactLogo} alt='react logo'
                            className='react-logo'/>
                            <img src={KnexLogo} alt='knex logo'
                            className='logo2'/>
                            <img src={SassLogo} alt='sass logo'
                            className='logo3'/>
                            <img src={NodeLogo} alt='node logo'
                            className='logo4'/>
                            <img src={MySQLLogo} alt='mysql logo'
                            className='logo5'/>
                            <img src={ReactRouterLogo} alt='react router logo'
                            className='logo6'/>
                            <img src={JWTLogo} alt='jwt logo'
                            className='logo7'/>
                            <img src={ExpressLogo} alt='express logo'
                            className='logo8'/>
                            <img src={BCryptLogo} alt='bcrypt logo'
                            className='logo9'/>
                        </div>
                        <img src={Rob}
                        onClick={this.handleRenderAbout}
                        className={this.state.renderAbout?
                            'about__top--info--image--disappear' :
                            'about__top--info--image'}
                        alt='robert profile'/>
                    </div>
                    <h1 className='about__top--name'
                    >
                        <span style={spanStyle1} onClick={this.handleColorChange1}>R</span>
                        <span style={spanStyle2} onClick={this.handleColorChange2}>o</span>
                        <span style={spanStyle3} onClick={this.handleColorChange3}>b</span>
                        <span style={spanStyle4} onClick={this.handleColorChange4}>e</span>
                        <span style={spanStyle5} onClick={this.handleColorChange5}>r</span>
                        <span style={spanStyle6} onClick={this.handleColorChange6}>t</span>
                        <span style={{cursor: 'pointer'}}> </span>
                        <span style={spanStyle7} onClick={this.handleColorChange7}>P</span>
                        <span style={spanStyle8} onClick={this.handleColorChange8}>e</span>
                        <span style={spanStyle9} onClick={this.handleColorChange9}>r</span>
                        <span style={spanStyle10} onClick={this.handleColorChange10}>r</span>
                        <span style={spanStyle11} onClick={this.handleColorChange11}>y</span>
                    </h1>
                </div>
                <div className='about__bottom'>
                    <a href='https://www.bates.edu'>
                        <img className='about__bottom--bates-logo'
                        src={BatesLogo} alt='bates college'/>
                    </a>
                    <p className='about__bottom--bates-degree'>
                        B.A. Mathematics, 2018
                    </p>
                    <a href='https://www.andovertownsman.com/sports/andover-jumpers-exceed-expectations-at-emass-states/article_bb3bef15-a095-53bd-ba9f-8644a0ef2592.html'>
                        <img className='about__bottom--ahs-logo'
                        src={AHSLogo} alt='andover high school'/>
                    </a>
                    <ul className='about__bottom--ahs-achievements'>
                        <li>High School Diploma, 2014</li>
                        <li>Distance Team Captain</li>
                        <li>Red Cross Club (3+ gallon donor)</li>
                    </ul>
                </div>
            </div>
        );    
    }
};

export default About;