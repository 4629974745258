import './Projects.scss'
import React from 'react';
import FrememberatrDesktop from '../../assets/images/Frememberatr_Desktop.png'

const Projects = () => {
    return (
        <div className='projects' id='projects'>
            <h2 className='projects__heading'>Projects</h2>
            <div className='projects__frememberatr'>
                <a href='https://frememberatr.herokuapp.com'>
                    <img className='projects__frememberatr--image'
                    src={FrememberatrDesktop} alt='frememberatr desktop'
                    />
                </a>
                <h3 className='projects__frememberatr--title'>Frememberatr</h3>
                <p className='projects__frememberatr--description'>
                    Frememberatr (friend - remember - 'ator') is a social app that helps users remember the personal information about the people in their life.</p>
            </div>
        </div>
    );
};

export default Projects;