import './App.scss';
import About from './components/About/About';
import Nav from './components/Nav/Nav';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <div className="App">
      <Nav/>
      <About/>
      <Projects/>
      <Contact/>
    </div>
  );
}

export default App;
