import './Contact.scss'
import React from 'react';
import GmailLogo from '../../assets/logos/gmail_logo.png';
import LinkedInLogo from '../../assets/logos/linkedin_logo.png';
import GithubLogo from '../../assets/logos/github_logo.png'

const Contact = () => {
    return (
        <div className='contact' id='contact'>
            <h2 className='contact__heading'>Contact</h2>
            <ul className='contact__list'>
                <li className='contact__list--item'>
                    <img className='contact__list--item--gmail-logo'
                    src={GmailLogo} alt='gmail'/>
                    r.james.perry@gmail.com
                </li>
                <li className='contact__list--item'>
                    <a className='contact__list--item--linkedin-link'
                    href='https://www.linkedin.com/in/robertperry800/'>
                        <img className='contact__list--item--linkedin-logo'
                        src={LinkedInLogo} alt='linkedin'/>
                        LinkedIn
                    </a>
                </li>
                <li className='contact__list--item'>
                    <a className='contact__list--item--github-link'
                    href='https://github.com/RJPerry23'>
                        <img className='contact__list--item--github-logo'
                        src={GithubLogo} alt='github'/>
                        Github
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Contact;